<style></style>

<div class="flex justify-between items-center mb-5">
  <div>
    <h2 class="mat-h2">Switch workspace</h2>
  </div>
  <div *ngIf="user.userInfo.isDAdmin">
    <button
      mat-button
      color="primary"
      [matMenuTriggerFor]="menu"
      aria-label="Switch tenant"
      title="Tenant"
      [disabled]="isLoading"
    >
      <mat-icon color="primary">business</mat-icon>
      <span class="pl-2">{{ selectedTenant.name }}</span>
      <mat-icon>expand_more</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before">
      <button
        mat-menu-item
        *ngFor="let tenant of tenants$ | async"
        [disabled]="tenant.id === selectedTenant.id"
        (click)="onTenantSelection(tenant)"
      >
        <span>{{ tenant.name }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<!-- <mat-dialog-content *ngIf="isLoading">

</mat-dialog-content> -->

<mat-dialog-content>
  <div class="content-container">
    <div class="px-6 flex justify-around content-spinner" *ngIf="isLoading">
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="30"> </mat-progress-spinner>
    </div>

    <div class="px-6">
      <mat-form-field appearance="outline">
        <mat-label>Search account</mat-label>
        <input matInput placeholder="Account name" #filter cdkFocusInitial autocomplete="off" />
        <!-- suffix -->
        <button
          mat-button
          *ngIf="filter.value; else searchIcon"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="resetFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <ng-template #searchIcon>
          <span matSuffix>
            <mat-icon>search</mat-icon>
          </span>
        </ng-template>
      </mat-form-field>
    </div>

    <div class="grid gap-3">
      <span class="mat-body-1 px-6">Available accounts</span>
      <button
        class="hover:bg-gray-100 focus:bg-gray-100"
        *ngFor="let account of account$ | async | filterAccounts: filter.value"
        (click)="onAccountSelection(account)"
      >
        <div class="flex justify-between items-center py-2 px-6">
          <div class="flex items-center">
            <div
              style="width: 32px; height: 32px;"
              class="bg-purple-200 rounded-full flex items-center justify-center text-lg"
            >
              {{ (account?.name)[0] }}
            </div>
            <div class="ml-3">
              <div class="font-medium text-left">{{ account.name }}</div>
              <div class="text-gray-600">{{ account.ownerInfo }}</div>
            </div>
          </div>
          <div class="text-gray-600" title="Creation date">
            {{ account.creationDate | orionDate: 'mediumDate' }}
          </div>
        </div>
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="w-full flex justify-between items-center pb-2 pt-5">
    <button mat-stroked-button color="primary" (click)="onAllAccountSelection()" [disabled]="isLoading">
      All Account
    </button>
    <button mat-button [mat-dialog-close]="null">Cancel</button>
  </div>
</mat-dialog-actions>
