import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OrionDatePipeModule } from '@app/pipes/date-pipe';
import { PluralizePipeModule } from '@app/pipes/pluralize-pipe';
import { OrionTitleCasePipeModule } from '@app/pipes/title-case-pipe';

@NgModule({
  exports: [CommonModule, OrionDatePipeModule, OrionTitleCasePipeModule, PluralizePipeModule],
})
export class SharedModule {}
