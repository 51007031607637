import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class DAdminRoutesGuard {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.canLoad();
  }

  canLoad(): Observable<boolean> | boolean {
    if (this.userService.userInfo.isDAdmin) {
      return true;
    }

    // User is not authenticated so redirect to login with the current url as returning url
    this.navigateToDashboard();
    return false;
  }

  private navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
