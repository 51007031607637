export const MENU_DOCS_URLS = {
  DASHBOARD: '/intro',
  SYSTEM_API_PARAMETERS: '/system/api-parameters',
  SYSTEM_WIDGETS: '/system/widgets',
  SYSTEM_CONFIG_PARAMETERS: '/system/config-parameters',
  SYSTEM_SITES: '/system/sites',
  TENANTS: '/tenants',
  MONITORED_MACHINES: '/monitored-machines',
  ACCOUNTS: '/accounts',
  SETUPS: '/setups/manage',
  SETUP_TEMPLATES: '/setups/templates',
  CLUSTER_NODES: '/cluster-nodes/manage',
  CLUSTER_NODES_TYPES: '/cluster-nodes/types',
  CLUSTER_NODES_OEMS: '/cluster-nodes/oems',
  CLUSTER_NODES_FIRMWARES: '/cluster-nodes/firmwares',
  CLUSTER_NODES_MODELS: '/cluster-nodes/models',
  CLUSTER_NODES_COMMON_CONFIGURATIONS: '/cluster-nodes/common-configurations',
  APP_PROCESSOR: '/app-processor/manage',
  APP_PROCESSOR_MODELS: '/app-processor/models',
  APP_PROCESSOR_COMMON_CONFIGURATIONS: '/app-processor/common-configurations',
  DEVICES: '/devices/manage',
  DEVICES_MODELS: '/devices/models',
  DEVICES_TYPES: '/devices/types',
  DEVICES_OEMS: '/devices/oems',
  DEVICES_FIRMWARES: '/devices/firmwares',
  DEVICES_COMMON_CONFIGURATIONS: '/devices/common-configurations',
  CLIENT_APPS: '/client-apps',
  CLIENT_APPS_MODELS: '/client-apps/models',
  CLIENT_APIS: '/client-apis',
  DEVICE_APPS_AGENTS: '/device-apps/agents',
  DEVICE_APPS_SDKS: '/device-apps/sdks',
  EVENTS_PREDICTIONS: '/events-&-predictions/registration',
  EVENTS_PREDICTIONS_RUNTIME_MODELS: '/events-&-predictions/runtime-model',
  FILES: '/files',
  REPORTS: '/reports',
  APP_JOBS: '/app-jobs/manage',
  APP_JOBS_MODELS: '/app-jobs/models',
  APP_JOBS_COMMON_CONFIGURATIONS: '/app-jobs/common-configurations',
};
