import { Component, OnInit, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'operation-state-col',
  template: `
    <div>
      <div>{{ operationState | titlecase }}</div>
      <div
        class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none  rounded-full"
        [ngClass]="getStatusClass()"
      >
        {{ api_response_status }}
      </div>
    </div>
  `,
})
export class OperationStateColComponent {
  @Input() operationState;
  @Input() api_response_status;

  constructor() {}

  getStatusClass() {
    return {
      'text-white bg-teal-600': this.api_response_status >= 200 && this.api_response_status < 300,
      'text-white bg-yellow-300': this.api_response_status >= 300 && this.api_response_status < 400,
      'text-white bg-red-600': this.api_response_status >= 400,
    };
  }
}
