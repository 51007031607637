import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'operation-api-col',
  template: `
    <div>
      <div>{{ apiName | titlecase }}</div>
      <div class="mat-caption mat-hint">{{ userName }}</div>
    </div>
  `,
})
export class OperationApiColComponent {
  @Input() apiName;
  @Input() userName;

  constructor() {}
}
