import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BreadCrumbItem } from './bread-crumb-item.model';

@Component({
  selector: 'm3-bread-crumb',
  template: `
    <ul class="mat-body-strong breadcrumb pl-0">
      <li *ngFor="let i of items">
        <a *ngIf="i.url; else activeLinkRef" class="link" [routerLink]="[i.url]">{{ i.title }}</a>

        <ng-template #activeLinkRef>
          <a class="active-item">{{ activeItemTitle || i.title }}</a>
        </ng-template>
      </li>
    </ul>
  `,
  styleUrls: ['./bread-crumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadCrumbComponent {
  @Input() items: Array<BreadCrumbItem> = [];

  @Input() activeItemTitle = '';

  constructor() {}
}
