import { APP_INITIALIZER } from '@angular/core';
import { ConfigService } from './state/config.service';

export function loadConfig(configService: ConfigService) {
  return () => configService.setConfig();
}

export const appInitializerProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: loadConfig,
    deps: [ConfigService],
    multi: true,
  },
];
