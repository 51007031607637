<mat-toolbar class="toolbar">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="onToggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <div class="border-r pr-1 mr-4 text-gray-600" *ngIf="user.userInfo.isDAdminOrTAdmin">
    <button mat-button (click)="toggleTenantOrAccount()">
      <span *ngIf="user.userInfo.isDAdmin">{{ (user.userInfo$ | async).tenantName }} &ndash; </span>
      <span>{{ (user.userInfo$ | async).accountName }}</span>
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>

  <div>
    <m3-bread-crumb [items]="breadCrumb.items$ | async" [activeItemTitle]="breadCrumb.activeItemTitle$ | async">
    </m3-bread-crumb>
  </div>

  <span class="flex-1"></span>

  <button mat-icon-button matTooltip="Docs" (click)="onDocsClick()">
    <mat-icon>help_outline</mat-icon>
  </button>

  <m3-notifications-count-icon-button></m3-notifications-count-icon-button>

  <m3-operations-icon-button (showOperations)="onOperationsClick()"></m3-operations-icon-button>

  <button mat-icon-button [matMenuTriggerFor]="profileMenu">
    <mat-icon>person</mat-icon>
  </button>

  <mat-menu #profileMenu="matMenu">
    <m3-profile-menu [user]="user.userInfo$ | async"></m3-profile-menu>
  </mat-menu>
</mat-toolbar>
