import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { Observable, Subscription } from 'rxjs';

import { SystemOperation, SingleSystemOperationResponse } from '../model';

export interface OperationDetailsDialogData {
  operation: SystemOperation;
  operationDetails$: Observable<SingleSystemOperationResponse>;
}

@Component({
  selector: 'm3-operation-details-dialog',
  templateUrl: './operation-details-dialog.component.html',
  styles: [
    `
      .detail-item {
        margin-bottom: 0.4rem;
      }
    `,
  ],
})
export class OperationDetailsDialogComponent implements OnInit, OnDestroy {
  operation: SystemOperation;

  isLoading: boolean;
  details: any;
  subscription: Subscription;
  error: string;
  duratin: any;

  displayedColumns: string[] = ['api', 'state', 'duration'];

  constructor(@Inject(MAT_DIALOG_DATA) private data: OperationDetailsDialogData) {}

  ngOnInit(): void {
    this.operation = this.data.operation;
    this.getDetails();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  getDetails() {
    this.isLoading = true;
    this.subscription = this.data.operationDetails$.subscribe(
      (resp: any) => {
        this.duratin = this.calculateDifference(resp[0].requestOutTime, resp[resp.length - 1].requestTime);
        const newOperationDetail = resp.map((op: SystemOperation) => ({
          ...op,
          differenceInTime: this.calculateDifference(op.requestOutTime, op.requestTime),
        }));

        // sort for latest operation on bottom
        const newOperationsAfterSort = newOperationDetail.sort((a, b) => {
          return <any>new Date(b.creationTime) - <any>new Date(a.creationTime);
        });
        this.details = newOperationsAfterSort;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.error = 'Error occurred getting the activity details';
      }
    );
  }

  private calculateDifference(endDate: any, startDate: any) {
    const eDate: any = new Date(endDate);
    const sDate: any = new Date(startDate);
    const diffTime = Math.abs(eDate - sDate);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");
    return diffTime; // it will returns milliseconds
  }
}
