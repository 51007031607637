import { Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpClientWrapper } from '@app/core/services';
import { UserService } from '@app/state';

@Component({
  selector: 'm3-notifications-count-icon-button',
  template: `
    <a mat-icon-button routerLink="/notifications" matTooltip="Notifications">
      <mat-icon [matBadge]="unreadCount" [matBadgeHidden]="!unreadCount" matBadgeSize="small">
        {{ unreadCount ? 'notifications' : 'notifications_none' }}
      </mat-icon>
    </a>
  `,
  styles: [],
})
export class NotificationsCountIconButtonComponent implements OnInit, OnDestroy {
  unreadCount = 0;
  pollingSubscription: Subscription;
  userInfoSubscription: Subscription;

  constructor(private http: HttpClientWrapper, private userService: UserService) {}

  ngOnInit(): void {
    this.userInfoSubscription = this.userService.userInfo$.subscribe((userInfo) => {
      this.pollNotificationCount();
    });
  }

  ngOnDestroy() {
    this.pollingSubscription?.unsubscribe();
    this.userInfoSubscription?.unsubscribe();
  }

  private pollNotificationCount() {
    this.pollingSubscription?.unsubscribe();
    this.pollingSubscription = timer(0, 10000)
      .pipe(
        switchMap(() => this.getUnreadNotificationCount()),
        catchError((error) => {
          console.log('unable to fetch latest notifications count');
          return of(0);
        })
      )
      .subscribe((count) => (this.unreadCount = count));
  }

  private getUnreadNotificationCount(): Observable<number> {
    return this.http.get(`/user-services/v3/notifications/unread-count`).pipe(map((resp: any) => resp?.message || 0));
  }
}
