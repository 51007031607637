import { Component, Input, Output, EventEmitter } from '@angular/core';

import { UserService } from '@app/state';
import { BreadCrumbService } from '@app/core/services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TenantOrAccountSwitchDialogComponent } from '../account-tenant-switch-dialog';

@Component({
  selector: 'm3-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @Input() isHandset: boolean;
  @Output() toggleSidebar = new EventEmitter();
  @Output() showLatestOperations = new EventEmitter();
  @Output() showDocs = new EventEmitter();

  constructor(public user: UserService, public breadCrumb: BreadCrumbService, private dialogRef: MatDialog) {}

  public onToggleSidebar(): void {
    this.toggleSidebar.emit();
  }

  public toggleTenantOrAccount(): void {
    if (!this.user.userInfo.isDAdminOrTAdmin) {
      return;
    }

    this.dialogRef.open(TenantOrAccountSwitchDialogComponent, {
      width: '600px',
      position: {
        top: '5%',
      },
    });
  }

  public onOperationsClick() {
    this.showLatestOperations.emit();
  }

  public onDocsClick() {
    this.showDocs.emit();
  }
}
