import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'milliToHumanTime',
})
export class MilliToHumanTimePipe implements PipeTransform {
  transform(value: number): string {
    if (isNaN(value) || value <= 0) {
      return '0 ms';
    }

    const ms = value % 1000;
    value = (value - ms) / 1000;
    const secs = value % 60;
    value = (value - secs) / 60;
    const mins = value % 60;
    value = (value - mins) / 60;
    const hrs = value % 24;
    const days = (value - hrs) / 24;

    // return days +':'+ hrs + ':' + mins + ':' + sec + '.' + ms;

    return this.getTimeString(days, hrs, mins, secs, ms);

    // output 00:00:03.000,  only for 24 hours
    // return new Date(value).toISOString().slice(11, -1);
  }

  private getTimeString(days, hrs, mins, secs, ms) {
    let result = '';
    const seperator = ', ';

    if (days > 0) {
      result += days > 1 ? `${days} days` : `${days} day`;
    }
    if (hrs > 0) {
      if (result.length) {
        result += seperator;
      }
      result += hrs > 1 ? `${hrs} hrs` : `${hrs} hr`;
    }
    if (mins > 0) {
      if (result.length) {
        result += seperator;
      }
      result += mins > 1 ? `${mins} mins` : `${mins} min`;
    }
    if (secs > 0) {
      if (result.length) {
        result += seperator;
      }
      result += `${secs} sec`;
    }
    if (ms > 0) {
      if (result.length) {
        result += seperator;
      }
      result += `${ms} ms`;
    }
    return result;
  }
}
