import { Injectable } from '@angular/core';

import { HttpClientWrapper } from '../../services';
import { Observable } from 'rxjs';
import { LoginResponse } from '@app/pages/auth/shared/login.model';
import { map } from 'rxjs/operators';

export interface TenantInfo {
  id?: string;
  name?: string;
  masterAccountId?: string;
  logoFileId?: string;
  tenantEmail?: string;
  signupUrl?: string;
  signinUrl?: string;
  status?: string;
  companyId?: string;
  creationDate?: string;
}

export interface AccountInfo {
  id: string;
  companyId: string;
  name: string;
  creationDate: string;
  status: string;
  ownerInfo: string;
}

@Injectable()
export class TenantOrAccountSwitchService {
  constructor(private http: HttpClientWrapper) {}

  getTenantsList(): Observable<TenantInfo[]> {
    return this.http.get(`/user-services/v3/tenants?status=active`);
  }

  getAccounts(tenantId: string): Observable<AccountInfo[]> {
    return this.http.get(`/user-services/v3/tenants/${tenantId}/accounts?status=active`);
  }

  switchWorkspace(tenantId: string, accountId: string): Observable<LoginResponse> {
    return this.http
      .post(`/user-services/v3/user/switch-workspace/${tenantId}/${accountId}`)
      .pipe(map((resp) => new LoginResponse(resp as any)));
  }
}
