import { Dictionary, PORTAL_ROLES } from '@app/state';

export interface MenuItem {
  role: string;
  label: string;
  url?: string; // only children or top level item without children can have url
  icon?: string; // only top level item can have icon
  children?: Array<MenuItem>; // only top level item can have children
  exactMatchHighlight?: boolean; // only in case of children, and on child with base route only, to keep sidenav highlighting sane
  divider?: boolean; // only for show the divider after some menu items
}

function generateDefaultMenu(dictionary: Dictionary): Array<MenuItem> {
  return [
    {
      role: null,
      label: `${dictionary.dashboard.toTitleCase()}`,
      url: '/dashboard',
      icon: 'dashboard',
      children: null,
    },

    // divider
    {
      role: null,
      label: null,
      url: null,
      icon: null,
      children: null,
      divider: true,
    },

    // System Menu Item
    {
      role: PORTAL_ROLES.SYSTEM,
      label: `${dictionary.system.toTitleCase()}`,
      icon: 'category',
      children: [
        {
          role: PORTAL_ROLES.SYSTEM_API_PARAMETERS,
          label: `${dictionary.apiParameter.toTitleCase().toPlural()}`,
          url: '/system/api-parameters',
        },
        {
          role: PORTAL_ROLES.SYSTEM_WIDGETS,
          label: `${dictionary.widget.toTitleCase().toPlural()}`,
          url: '/system/widgets',
        },
        {
          role: PORTAL_ROLES.SYSTEM_CONFIG_PARAMETERS,
          label: `${dictionary.configParameter.toTitleCase().toPlural()}`,
          url: '/system/config-parameters',
        },
        {
          role: PORTAL_ROLES.SYSTEM_SITES,
          label: `${dictionary.site.toTitleCase().toPlural()}`,
          url: '/system/sites',
        },
      ],
    },

    // Accounts
    {
      role: PORTAL_ROLES.USERS,
      label: `${dictionary.account.toTitleCase().toPlural()}`,
      url: '/accounts',
      icon: 'people_alt',
      children: null,
    },

    // Tenants
    {
      role: PORTAL_ROLES.TENANTS,
      label: `${dictionary.tenant.toTitleCase().toPlural()}`,
      url: '/tenants',
      icon: 'business',
      children: null,
    },

    // divider
    {
      role: null,
      label: null,
      url: null,
      icon: null,
      children: null,
      divider: true,
    },

    // Monitored Machines
    {
      role: PORTAL_ROLES.MONITORED_MACHINES,
      label: `${dictionary.machine.toTitleCase().toPlural()}`,
      url: '/machines',
      icon: 'settings',
      children: null,
    },

    //Setups
    {
      role: PORTAL_ROLES.SETUPS,
      label: `${dictionary.setup.toTitleCase().toPlural()}`,
      icon: 'group_work',
      children: [
        {
          role: PORTAL_ROLES.SETUPS,
          label: `${dictionary.manage.toTitleCase()}`,
          url: '/setups',
          exactMatchHighlight: true,
        },
        {
          role: PORTAL_ROLES.SETUPS,
          label: 'Templates',
          url: '/setups/templates',
        },
      ],
    },

    // Edge Apps/ App Processor
    {
      role: PORTAL_ROLES.APP_PROCESSOR,
      label: `${dictionary.appProcessor.toTitleCase().toPlural()}`,
      icon: 'apps',
      children: [
        {
          role: PORTAL_ROLES.APP_PROCESSOR_MANAGE,
          label: `${dictionary.manage.toTitleCase()}`,
          url: '/app-processors',
          exactMatchHighlight: true,
        },
        // {
        //   role: PORTAL_ROLES.EDGE_APPS_SDKS,
        //   label: `${dictionary.sdk.toUpperCase().toPlural()}`,
        //   url: '/edge-apps/sdks',
        // },
        {
          role: PORTAL_ROLES.APP_PROCESSOR_MODELS,
          label: `${dictionary.model.toTitleCase().toPlural()}`,
          url: '/app-processors/models',
        },
        {
          role: PORTAL_ROLES.APP_PROCESSOR_PLUGIN_PACKAGES,
          label: `${dictionary.pluginPackage.toTitleCase().toPlural()}`,
          url: '/app-processors/plugins',
        },
        // {
        //   role: PORTAL_ROLES.APP_PROCESSOR_METADATA,
        //   label: `${dictionary.apMetadata.toTitleCase()}`,
        //   url: '/app-processors/metadata',
        // },
        // {
        //   role: PORTAL_ROLES.EDGE_APPS_VOLUME_BACKUPS,
        //   label: `Volume Backups`,
        //   url: '/app-processors/volume-backups',
        // },
        {
          role: PORTAL_ROLES.APP_PROCESSOR_COMMON_CONFIGURATIONS,
          label: `${dictionary.commonConfig.toTitleCase().toPlural()}`,
          url: '/app-processors/common-configurations/configurations',
        },
      ],
    },

    // APP Jobs are not supported by system right now so disabling it as of now.
    // {
    //   role: PORTAL_ROLES.APP_JOBS,
    //   label: `${dictionary.appJob.toTitleCase().toPlural()}`,
    //   icon: 'ballot',
    //   children: [
    //     {
    //       role: PORTAL_ROLES.APP_JOBS_MANAGE,
    //       label: `${dictionary.manage.toTitleCase()}`,
    //       url: '/app-jobs',
    //       exactMatchHighlight: true,
    //     },
    //     {
    //       role: PORTAL_ROLES.APP_JOBS_MODELS,
    //       label: `${dictionary.model.toTitleCase().toPlural()}`,
    //       url: '/app-jobs/models',
    //     },
    //     {
    //       role: PORTAL_ROLES.APP_JOBS_COMMON_CONFIGURATIONS,
    //       label: `${dictionary.commonConfig.toTitleCase().toPlural()}`,
    //       url: '/app-jobs/common-configurations/configurations',
    //     },
    //   ],
    // },

    // Device APPs
    {
      role: PORTAL_ROLES.DEVICE_APPS,
      label: `${dictionary.peripheralApp.toTitleCase().toPlural()}`,
      url: '/device-apps',
      icon: 'apps',
      children: [
        {
          role: PORTAL_ROLES.DEVICE_APPS_AGENTS,
          label: `${dictionary.agent.toTitleCase().toPlural()}`,
          url: '/device-apps/agents',
        },
        {
          role: PORTAL_ROLES.DEVICE_APPS_AGENTS,
          label: `${dictionary.sdk.toUpperCase().toPlural()}`,
          url: '/device-apps/sdks',
        },
      ],
    },

    // Client Apps
    // {
    //   role: PORTAL_ROLES.CLIENT_APPS,
    //   label: `${dictionary.clientApp.toTitleCase().toPlural()}`,
    //   url: '/client-apps',
    //   icon: 'extension',
    //   children: null,
    // },
    {
      role: PORTAL_ROLES.CLIENT_APPS,
      label: `${dictionary.clientApp.toTitleCase().toPlural()}`,
      icon: 'extension',
      children: [
        {
          role: PORTAL_ROLES.CLIENT_APPS_MANAGE,
          label: `${dictionary.manage.toTitleCase()}`,
          url: '/client-apps',
          exactMatchHighlight: true,
        },
        {
          role: PORTAL_ROLES.CLIENT_APPS_MODEL,
          label: dictionary.model.toTitleCase().toPlural(),
          url: '/client-apps/models',
        },
      ],
    },

    // Client APIs
    {
      role: PORTAL_ROLES.CLIENT_APIS,
      label: `${dictionary.clientApi.toTitleCase().toPlural()}`,
      url: '/client-apis',
      icon: 'api',
      children: null,
    },

    // divider
    {
      role: null,
      label: null,
      url: null,
      icon: null,
      children: null,
      divider: true,
    },

    // Infra Nodes
    {
      role: PORTAL_ROLES.INFRA_OR_EXTERNAL_NODES,
      label: `${dictionary.infraNode.toTitleCase().toPlural()}`,
      url: '/infra-nodes',
      icon: 'workspaces',
      children: null,
    },

    // Edge-Cores / cluster nodes
    {
      role: PORTAL_ROLES.EDGE_CORES,
      label: `${dictionary.edgeCore.toTitleCase().toPlural()}`,
      icon: 'developer_board',
      children: [
        {
          role: PORTAL_ROLES.EDGE_CORES_MANAGE,
          label: `${dictionary.manage.toTitleCase()}`,
          url: '/cluster-nodes',
          exactMatchHighlight: true,
        },
        {
          role: PORTAL_ROLES.EDGE_CORES_MODELS,
          label: dictionary.model.toTitleCase().toPlural(),
          url: '/cluster-nodes/models',
        },
        {
          role: PORTAL_ROLES.EDGE_CORES_OEM,
          label: dictionary.oem.toUpperCase().toPlural(),
          url: '/cluster-nodes/oems',
        },
        {
          role: PORTAL_ROLES.EDGE_CORES_TYPES,
          label: `${dictionary.type.toTitleCase().toPlural()}`,
          url: '/cluster-nodes/types',
        },
        {
          role: PORTAL_ROLES.EDGE_CORES_FIRMWARE,
          label: `${dictionary.firmware.toTitleCase().toPlural()}`,
          url: '/cluster-nodes/firmwares',
        },
        {
          role: PORTAL_ROLES.EDGE_CORES_COMMON_CONFIGURATIONS,
          label: `${dictionary.commonConfig.toTitleCase().toPlural()}`,
          url: '/cluster-nodes/common-configurations/configurations',
        },
      ],
    },

    // Devices
    {
      role: PORTAL_ROLES.DEVICES,
      label: `${dictionary.peripheralDevice.toTitleCase().toPlural()}`,
      icon: 'device_hub',
      children: [
        {
          role: PORTAL_ROLES.DEVICES_MANAGE,
          label: `${dictionary.manage.toTitleCase()}`,
          url: '/devices',
          exactMatchHighlight: true,
        },
        {
          role: PORTAL_ROLES.DEVICES_MODELS,
          label: dictionary.model.toTitleCase().toPlural(),
          url: '/devices/models',
        },
        {
          role: PORTAL_ROLES.DEVICES_TYPES,
          label: `${dictionary.type.toTitleCase().toPlural()}`,
          url: '/devices/types',
        },
        {
          role: PORTAL_ROLES.DEVICES_OEM,
          label: `${dictionary.oem.toUpperCase().toPlural()}`,
          url: '/devices/oems',
        },
        {
          role: PORTAL_ROLES.DEVICES_FIRMWARE,
          label: `${dictionary.firmware.toTitleCase().toPlural()}`,
          url: '/devices/firmwares',
        },
        {
          role: PORTAL_ROLES.DEVICES_COMMON_CONFIGURATIONS,
          label: `${dictionary.commonConfig.toTitleCase()}`,
          url: '/devices/common-configurations/configurations',
        },
      ],
    },

    // divider
    {
      role: null,
      label: null,
      url: null,
      icon: null,
      children: null,
      divider: true,
    },

    // Events & Predictions
    {
      role: PORTAL_ROLES.EVENTS_PREDICTIONS,
      label: `Events & Predictions`,
      icon: 'flag',
      children: [
        {
          role: PORTAL_ROLES.EVENTS_PREDICTIONS,
          label: `Registration`,
          url: '/events-predictions',
          exactMatchHighlight: true,
        },
        {
          role: PORTAL_ROLES.EVENTS_PREDICTIONS,
          label: `Event Occurrences`,
          url: '/events-predictions/occurrences',
        },
        {
          role: PORTAL_ROLES.RUNTIME_MODELS,
          label: `Runtime Models`,
          url: '/events-predictions/runtime-models',
        },
      ],
    },
    // Operational Data
    {
      role: PORTAL_ROLES.EVENTS_PREDICTIONS,
      label: `${dictionary.operationalData.toTitleCase()}`,
      url: '/operational-data',
      icon: 'data_object',
      children: null,
    },
    // File Browser
    {
      role: PORTAL_ROLES.FILES,
      label: `${dictionary.file.toTitleCase().toPlural()}`,
      url: '/file-browser',
      icon: 'folder',
      children: null,
    },

    // Reports hiding from menu as of now, will be enabled once reports are ready.
    // {
    //   role: PORTAL_ROLES.REPORTS,
    //   label: `${dictionary.report.toTitleCase().toPlural()}`,
    //   url: '/reports',
    //   icon: 'receipt',
    //   children: null,
    // },
  ];
}

function filterMenu(menuItems: Array<MenuItem>, roles: Array<string>): Array<MenuItem> {
  const menu = [];
  const tempMenu = [];

  menuItems.forEach((item) => {
    if (!item.role || roles.includes(item.role)) {
      if (!item.children || !item.children.length) {
        tempMenu.push({ ...item });
        return;
      }

      const subMenu = [];
      item.children.forEach((child) => {
        if (!child.role || roles.includes(child.role)) {
          subMenu.push({ ...child });
        }
      });
      tempMenu.push({ ...item, children: subMenu });
    }
  });

  // Just to remove the duplication of divider in case of role exclusion.
  if (tempMenu.length) {
    menu.push(tempMenu[0]);
    for (let i = 1; i < tempMenu.length; i++) {
      const prevItem = tempMenu[i - 1];
      const currItem = tempMenu[i];
      if ((currItem.divider && !prevItem.divider) || !currItem.divider) {
        menu.push(currItem);
      }
    }
  }
  return menu;
}

export function getFilteredNavigationMenu(roles: Array<string>, dictionary: Dictionary): Array<MenuItem> {
  const defaultMenu = generateDefaultMenu(dictionary);
  return filterMenu(defaultMenu, roles);
}
