export const PORTAL_ROLES = {
  SYSTEM: '1ab2ce27-95d5-11ea-a722-98e7435ea37e',
  SYSTEM_API_PARAMETERS: '1ab32544-95d5-11ea-a722-98e7435ea37e',
  SYSTEM_WIDGETS: '1ab3355c-95d5-11ea-a722-98e7435ea37e', // SYSTEM_ASPECTS_VIEWS
  SYSTEM_CONFIG_PARAMETERS: '1ab345a4-95d5-11ea-a722-98e7435ea37e',
  SYSTEM_SITES: '1ab355dc-95d5-11ea-a722-98e7435ea37e',
  TENANTS: '1ab3664c-95d5-11ea-a722-98e7435ea37e',
  TENANTS_NEW: '1ab37660-95d5-11ea-a722-98e7435ea37e',
  TENANTS_MANAGE: '1ab38694-95d5-11ea-a722-98e7435ea37e',
  USERS: '1ab3974b-95d5-11ea-a722-98e7435ea37e',
  MONITORED_MACHINES: 'd6f78165-7083-11eb-9b16-00059a3c7a00',
  SETUPS: '1ab3b9dc-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES: '1ab3ce1f-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES_MANAGE: '1ab3df15-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES_MODELS: '4f4b5240-645d-11eb-a90c-cc483a336057',
  EDGE_CORES_COMMISSION: '1ab3ef32-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES_TYPES: '1ab3fdab-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES_OEM: '1ab40d55-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES_FIRMWARE: '1ab41992-95d5-11ea-a722-98e7435ea37e',
  EDGE_CORES_COMMON_CONFIGURATIONS: '1ab42631-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR: '1ab4337f-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR_MANAGE: '1ab44100-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR_SDKS: '1ab44c67-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR_MODELS: '1ab45677-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR_VOLUME_BACKUPS: '1ab45f3b-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR_COMMON_CONFIGURATIONS: '1ab4679c-95d5-11ea-a722-98e7435ea37e',
  APP_PROCESSOR_METADATA: '656c43dd-b754-4730-852d-237e0586d552',
  APP_PROCESSOR_PLUGIN_PACKAGES: '09aa7b8d-fdb3-4885-b4c0-02fdce0b34b3',
  DEVICES: '1ab46f6f-95d5-11ea-a722-98e7435ea37e',
  DEVICES_MANAGE: '1ab47756-95d5-11ea-a722-98e7435ea37e',
  DEVICES_MODELS: '1ab47f40-95d5-11ea-a722-98e7435ea37e',
  DEVICES_TYPES: '1ab48713-95d5-11ea-a722-98e7435ea37e',
  DEVICES_OEM: '1ab48ede-95d5-11ea-a722-98e7435ea37e',
  DEVICES_FIRMWARE: '1ab49738-95d5-11ea-a722-98e7435ea37e',
  DEVICES_COMMON_CONFIGURATIONS: '1ab49f1e-95d5-11ea-a722-98e7435ea37e',
  PIPELINES: '1ab4a6f5-95d5-11ea-a722-98e7435ea37e',
  CLIENT_APPS: '1ab4aeb1-95d5-11ea-a722-98e7435ea37e',
  CLIENT_APPS_MANAGE: '1ab4aeb1-95d5-11ea-a722-98e7435ea371',
  CLIENT_APPS_MODEL: '1ab4aeb1-95d5-11ea-a722-98e7435ea372',
  CLIENT_APIS: '1ab4b678-95d5-11ea-a722-98e7435ea37e',
  DEVICE_APPS: '1ab4be37-95d5-11ea-a722-98e7435ea37e',
  DEVICE_APPS_AGENTS: '1ab4c5fe-95d5-11ea-a722-98e7435ea37e',
  DEVICE_APPS_SDKS: '1ab4cdc5-95d5-11ea-a722-98e7435ea37e',
  EVENTS_PREDICTIONS: '1ab4d594-95d5-11ea-a722-98e7435ea37e',
  RUNTIME_MODELS: '1ab4d594-95d5-11ea-a722-98e7435ea370',
  FILES: '1ab4dd57-95d5-11ea-a722-98e7435ea37e',
  REPORTS: '1ab4e516-95d5-11ea-a722-98e7435ea37e',
  INFRA_OR_EXTERNAL_NODES: '43712d08-c06a-11ec-ab0d-b62bcf9d9d78',

  APP_JOBS: 'a7d0186f-3949-11eb-8b41-cc483a336057',
  APP_JOBS_MANAGE: '252aa143-6216-11eb-a9e5-cc483a336057',
  APP_JOBS_MODELS: '252a4b7a-6216-11eb-a9e5-cc483a336057',
  APP_JOBS_PACKAGES: '252a90c3-6216-11eb-a9e5-cc483a336057',
  APP_JOBS_COMMON_CONFIGURATIONS: '1ab4679c-95d5-11ea-a722-98e7435ea37e',
};
