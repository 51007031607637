import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Observable } from 'rxjs';

import { SystemOperation } from '../model';
import { LatestOperationsService } from './latest-operations.service';
import {
  OperationDetailsDialogData,
  OperationDetailsDialogComponent,
} from '../operation-details-dialog/operation-details-dialog.component';

@Component({
  selector: 'm3-latest-operations',
  templateUrl: './latest-operations.component.html',
  styleUrls: ['./latest-operations.component.scss'],
})
export class LatestOperationsComponent implements OnInit {
  @Output() hide = new EventEmitter();

  latestOperations$: Observable<SystemOperation[]>;
  isLoadingOperations$: Observable<boolean>;

  constructor(private latestOperationsService: LatestOperationsService, private dialog: MatDialog) {}

  ngOnInit() {
    this.isLoadingOperations$ = this.latestOperationsService.loadingIndicator$;
    this.latestOperations$ = this.latestOperationsService.latestOperations$;
  }

  onSelect(operation: SystemOperation) {
    const data: OperationDetailsDialogData = {
      operation: operation,
      operationDetails$: this.latestOperationsService.getOperationDetails(operation.operationId),
    };

    this.dialog.open(OperationDetailsDialogComponent, {
      data,
      width: '800px',
      position: {
        top: '5%',
      },
    });
  }

  restartPooling(): void {
    this.latestOperationsService.restartPolling();
  }

  hideSidebar(): void {
    this.hide.emit();
  }
}
