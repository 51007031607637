enum UserType {
  D_ADMIN = 'D_ADMIN',
  T_ADMIN = 'T_ADMIN',
  A_ADMIN = 'A_ADMIN',
}

export const ALL_ACCOUNT_ID = '0e569d86-3a8a-460d-bd69-8c8288e7b3d6';

export class UserInfo {
  public userId: string;
  public fullName: string;
  public firstName: string;
  public nameFirstLetter: string;
  public lastName: string;
  public email: string;
  public tenantId: string;
  public tenantName: string;
  public userType: UserType;
  public clientType: string;
  public clientTypeId: string;
  public accountId: string;
  public accountName: string;
  public tenantAdminAccountId: string;
  public tenantAdminUserId: string;

  public isDAdmin: boolean;
  public isTAdmin: boolean;
  public isAAdmin: boolean;
  public isAccountOwner: boolean;
  public withAllAccountSelected: boolean;
  public isDAdminOrTAdmin: boolean;
  public isDAdminWithAllAccount: boolean;
  public isTAdminWithAllAccount: boolean;
  public isTAdminOrDAdminWithAllAccount: boolean;

  constructor(userData: any = {}) {
    this.userId = userData.userId;
    this.firstName = userData.firstName;
    this.lastName = userData.lastName;
    this.nameFirstLetter = userData.firstName ? userData.firstName[0] : '-';
    this.email = userData.email;
    this.tenantId = userData.tenantId;
    this.tenantName = userData.tenantName;
    this.userType = userData.userType;
    this.clientType = userData.clientType;
    this.clientTypeId = userData.clientTypeId;
    this.accountId = userData.accountId;
    this.accountName = userData.accountName;
    this.tenantAdminAccountId = userData.tenantAdminAccountId;
    this.tenantAdminUserId = userData.tenantAdminUserId;
    this.isAccountOwner = userData.isAccountOwner;

    this.fullName = this.firstName + ' ' + this.lastName;

    // these are helper properties
    this.isDAdmin = this._isDAdmin();
    this.isTAdmin = this._isTAdmin();
    this.isAAdmin = this._isAAdmin();
    this.withAllAccountSelected = this._withAllAccountSelected();
    this.isDAdminOrTAdmin = this._isDAdminOrTAdmin();
    this.isDAdminWithAllAccount = this._isDAdminWithAllAccount();
    this.isTAdminWithAllAccount = this._isTAdminWithAllAccount();
    this.isTAdminOrDAdminWithAllAccount = this._sDAdminOrTAdminWithAllAccount();
  }

  private _isDAdmin(): boolean {
    return this.userType === UserType.D_ADMIN;
  }

  private _isTAdmin(): boolean {
    return this.userType === UserType.T_ADMIN;
  }

  private _isAAdmin(): boolean {
    return this.userType === UserType.A_ADMIN;
  }

  private _withAllAccountSelected(): boolean {
    return this.accountId === ALL_ACCOUNT_ID;
  }

  private _isDAdminOrTAdmin(): boolean {
    return this._isDAdmin() || this._isTAdmin();
  }

  private _isDAdminWithAllAccount(): boolean {
    return this._isDAdmin() && this._withAllAccountSelected();
  }

  private _isTAdminWithAllAccount(): boolean {
    return this._isTAdmin() && this._withAllAccountSelected();
  }

  private _sDAdminOrTAdminWithAllAccount(): boolean {
    return this._isDAdminWithAllAccount() || this._isTAdminWithAllAccount();
  }
}
