import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AppStateService } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private appState: AppStateService) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (this.appState.user.isAuthenticated()) {
      return true;
    }

    // User is not authenticated so redirect to login with the current url as returning url
    this.appState.clearAuthState();
    this.navigateToLogin(state);
    return false;
  }

  private navigateToLogin(state: RouterStateSnapshot): void {
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: state.url },
    });
  }
}
