<mat-toolbar color="dark">
  <h3 cdkFocusInitial>Operation-Activities</h3>
  <span class="flex-1"></span>
  <button mat-icon-button (click)="restartPooling()" matTooltip="Refresh">
    <mat-icon>refresh</mat-icon>
  </button>
  <a mat-icon-button routerLink="/activities" matTooltip="View all activities" (click)="hideSidebar()">
    <mat-icon>view_list</mat-icon>
  </a>
</mat-toolbar>

<div class="relative">
  <div *ngIf="isLoadingOperations$ | async" class="absolute top-0 left-0 right-0 z-10">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>

  <mat-action-list>
    <button
      *ngFor="let operation of latestOperations$ | async"
      mat-list-item
      [class.bg-green-100]="!operation.userName"
      (click)="onSelect(operation)"
    >
      <span mat-list-icon>
        <system-operation-indicator
          [status]="operation.operationState"
          [tooltipText]="operation.operationState"
          tooltipPosition="below"
        ></system-operation-indicator>
      </span>
      <div mat-line>{{ operation.apiName | titlecase }}</div>
      <div mat-line class="mat-hint">
        <small>{{ operation.requestTime | orionDate }}</small>
      </div>
    </button>
  </mat-action-list>
</div>
