<mat-toolbar color="dark">
  <img width="120" height="20" src="assets/images/siemens-logo.svg" alt="siemens logo" />
</mat-toolbar>

<mat-accordion id="m3-sidenav-menu" [@.disabled]="disableAnimation" displayMode="flat">
  <ng-container *ngFor="let item of menuItems">
    <mat-nav-list *ngIf="!item?.children?.length; else itemWithSubMenu">
      <a
        mat-list-item
        [routerLink]="[item.url]"
        routerLinkActive="active-link"
        class="standalone-category"
        *ngIf="!item?.divider"
      >
        <div class="parent-link">
          <i class="material-icons link-icon">{{ item.icon }}</i>
          <span class="link-text">{{ item.label }}</span>
        </div>
      </a>
      <div class="my-2 mx-2" *ngIf="item?.divider">
        <mat-divider></mat-divider>
      </div>
    </mat-nav-list>

    <ng-template #itemWithSubMenu>
      <mat-expansion-panel
        class="mat-elevation-z standalone-category"
        routerLinkActive="active-panel"
        #rla="routerLinkActive"
        [expanded]="rla.isActive"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="parent-link">
              <i class="material-icons link-icon">{{ item.icon }}</i>
              <span class="link-text">{{ item.label }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <a
            *ngFor="let child of item.children"
            mat-list-item
            [routerLink]="[child.url]"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: child.exactMatchHighlight }"
          >
            <span class="child-link">{{ child.label }}</span>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
    </ng-template>
  </ng-container>
</mat-accordion>
