import { Injectable } from '@angular/core';

import { Subscription, BehaviorSubject, Observable, interval, ReplaySubject } from 'rxjs';
import { switchMap, startWith, map } from 'rxjs/operators';

import { HttpClientWrapper } from '@app/core/services';
import { SystemOperation, SingleSystemOperationResponse } from '../model';

/*
 *  Service to fetch latest operations by pooling,
 *  which will be shown in right sidebar
 */
@Injectable()
export class LatestOperationsService {
  // delay in milliseconds
  private poolingDelay = 30 * 1000;
  private poolingSubscription: Subscription;
  private readOperationSubscription: Subscription;

  // true when pooling start and false after first response/error
  private loadingIndicatorSubject = new BehaviorSubject<boolean>(false);
  public readonly loadingIndicator$ = this.loadingIndicatorSubject.asObservable();

  private latestOperationsSubject = new ReplaySubject<SystemOperation[]>(1);
  public readonly latestOperations$ = this.latestOperationsSubject.asObservable();

  public readonly hasUnreadOperations$ = this.latestOperationsSubject.pipe(
    map((newOperations) => newOperations.some((operation) => operation))
  );

  constructor(private http: HttpClientWrapper) {}

  public getOperationDetails(operationId): Observable<any> {
    return this.http.get(`/operation-services/v3/operations/${operationId}`);
  }

  private getLatestOperations() {
    return this.http.get(`/operation-services/v3/operations`);
  }

  public markLatestOperationsAsRead() {
    this.readOperationSubscription = this.http
      .get(`/operation-services/v3/operations`)
      .subscribe((operations: { data: SystemOperation[]; scrollId?: string }) =>
        this.latestOperationsSubject.next(operations.data)
      );
  }

  public startPolling() {
    this.loadingIndicatorSubject.next(true);
    this.poolingSubscription = interval(this.poolingDelay)
      .pipe(
        startWith(0),
        switchMap(() => this.getLatestOperations())
      )
      .subscribe(
        (operations: { data: SystemOperation[]; scrollId?: string }) => {
          if (this.loadingIndicatorSubject.value) {
            this.loadingIndicatorSubject.next(false);
          }
          const temp = operations.data.map((op: SystemOperation) => ({
            ...op,
            differenceInTime: this.calculateDifference(op.requestOutTime, op.requestTime),
          }));

          // sort for latest operation on top
          const newOperationsAfterSort = temp.sort((a, b) => {
            return <any>new Date(a.creationTime) - <any>new Date(b.creationTime);
          });
          this.latestOperationsSubject.next(newOperationsAfterSort);
        },
        (err) => {
          this.loadingIndicatorSubject.next(false);
          console.error('Unable to pool for latest operations');
        }
      );
  }

  // stop pooling of latest operations
  public stopPolling() {
    this.poolingSubscription?.unsubscribe();
    // if any call to markAsRead is in progress cancel
    this.readOperationSubscription?.unsubscribe();
  }

  // restart pooling of latest operations
  public restartPolling() {
    this.stopPolling();
    this.startPolling();
  }

  public clearPreviousOperationsAndRestartPooling() {
    this.latestOperationsSubject.next([]);
    this.restartPolling();
  }

  private calculateDifference(endDate: any, startDate: any) {
    const eDate: any = new Date(endDate);
    const sDate: any = new Date(startDate);
    const diffTime = Math.abs(eDate - sDate);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");
    return diffTime; // it will returns milliseconds
  }
}
