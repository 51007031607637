import { Component, AfterViewInit, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

import { combineLatest, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DictionaryService, RolesService, UserService } from '@app/state';
import { MenuItem, getFilteredNavigationMenu } from './navigation-menu';

@Component({
  selector: 'm3-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements AfterViewInit, OnInit, OnDestroy {
  // Workaround for angular component issue #13870
  disableAnimation = true;
  menuItems: Array<MenuItem> = [];
  private subscription: Subscription;

  constructor(
    private rolesService: RolesService,
    private dictionaryService: DictionaryService,
    public user: UserService
  ) {}

  ngOnInit(): void {
    // TODO: find a better strategy to respond to roles and dictionary changes
    this.subscription = combineLatest(this.rolesService.roles$, this.dictionaryService.dictionary$)
      .pipe(debounceTime(50))
      .subscribe(([roles, dictionary]) => {
        this.menuItems = getFilteredNavigationMenu(roles, dictionary);
      });
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
