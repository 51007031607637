import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { UserInfo } from '@app/state/user-data.model';
import { LogoutConfirmationModalComponent } from '../logout-confirmation-modal/logout-confirmation-modal.component';

@Component({
  selector: 'm3-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent {
  @Input() user: UserInfo;

  constructor(public dialog: MatDialog) {}

  public confirmLogout(): void {
    this.dialog.open(LogoutConfirmationModalComponent);
  }
}
