import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from './utils/storage.util';

export interface Dictionary {
  dashboard: string;
  system: string;
  widget: string;
  account: string;
  manage: string;
  firmware: string;
  type: string;
  oem: string;
  model: string;
  runtimeModel: string;
  sdk: string;
  file: string;
  report: string;
  agent: string;
  site: string;
  pipeline: string;
  setup: string;
  import: string;
  apiParameter: string;
  configParameter: string;
  tenant: string;
  machine: string;
  edgeCore: string;
  appProcessor: string;
  pluginPackage: string;
  apMetadata: string;
  appJob: string;
  appJobPackage: string;
  peripheralDevice: string;
  peripheralApp: string;
  clientApp: string;
  clientApi: string;
  commonConfig: string;
  infraNode: string;
  affinityTag: string;
  operationalData: string;
}

const defaultDictionary: Dictionary = {
  dashboard: 'dashboard',
  system: 'system',
  widget: 'widget',
  account: 'account',
  manage: 'manage',
  firmware: 'firmware',
  type: 'type',
  oem: 'oem',
  model: 'model',
  sdk: 'sdk',
  file: 'file',
  report: 'report',
  agent: 'agent',
  site: 'site',
  pipeline: 'pipeline',
  setup: 'setup',
  import: 'import',
  apiParameter: 'API parameter',
  configParameter: 'config parameter',
  tenant: 'tenant',
  machine: 'machine',
  appProcessor: 'app processor',
  pluginPackage: 'plugin package',
  apMetadata: 'metadata',
  appJob: 'app job',
  appJobPackage: 'package',
  edgeCore: 'cluster node',
  peripheralDevice: 'device',
  peripheralApp: 'device app',
  clientApp: 'client app',
  clientApi: 'client API',
  commonConfig: 'common configuration',
  infraNode: 'infra node',
  affinityTag: 'affinity tag',
  operationalData: 'operational data',
  runtimeModel: 'Runtime Model',
};

const DICT_STORAGE_KEY = 'dict';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  private dictionarySubject = new BehaviorSubject<Dictionary>(defaultDictionary);
  public readonly dictionary$ = this.dictionarySubject.asObservable();

  constructor(private storage: LocalStorage) {
    const dictStr = this.storage.get(DICT_STORAGE_KEY);
    let dictionary: Dictionary;
    try {
      dictionary = JSON.parse(dictStr);
      if (dictionary) {
        this.updateDictionary(dictionary);
      }
    } catch (_) {}
  }

  public updateDictionary(newDictionary: Dictionary = {} as Dictionary): void {
    this.dictionarySubject.next({ ...defaultDictionary, ...newDictionary });

    if (newDictionary !== null && typeof newDictionary === 'object' && Object.keys(newDictionary).length) {
      this.storage.save(DICT_STORAGE_KEY, JSON.stringify(newDictionary));
    } else {
      this.storage.remove(DICT_STORAGE_KEY);
    }
  }

  public get dictionary(): Dictionary {
    return this.dictionarySubject.value;
  }
}
