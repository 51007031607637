import { Pipe, PipeTransform } from '@angular/core';

import { AccountInfo } from './switch.service';

@Pipe({ name: 'filterAccounts' })
export class FilterAccountsPipe implements PipeTransform {
  transform(allAccounts: AccountInfo[], searchTerm: string) {
    if (!allAccounts || !searchTerm) {
      return allAccounts;
    }
    return allAccounts.filter((hero) => hero.name.includes(searchTerm));
  }
}
