import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { BasicLayoutComponent } from '@app/core/components';
import { AuthGuard, GuestGuard, RolesGuard } from '@app/core/guards';
import { PORTAL_ROLES } from './state';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  /* non-authenticated routes */
  {
    path: '',
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginModule),
        canActivate: [GuestGuard],
      },
      {
        path: 'mfa', // multi factor auth
        loadChildren: () => import('./pages/auth/mfa/mfa.module').then((m) => m.MfaModule),
        canActivate: [GuestGuard],
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./pages/auth/user-registration/user-registration.module').then((m) => m.UserRegistrationModule),
        canActivate: [GuestGuard],
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./pages/auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
        canActivate: [GuestGuard],
      },
      {
        path: 'reset',
        loadChildren: () => import('./pages/auth/set-password/set-password.module').then((m) => m.SetPasswordModule),
        canActivate: [GuestGuard],
      },
    ],
  },

  /* authenticated routes */
  {
    path: '',
    component: BasicLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'system',
        loadChildren: () => import('./pages/system/system.module').then((m) => m.SystemModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.SYSTEM] },
      },
      {
        path: 'tenants',
        loadChildren: () => import('./pages/tenants/tenants.module').then((m) => m.TenantsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.TENANTS] },
      },
      {
        path: 'accounts',
        loadChildren: () => import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.USERS] },
      },
      {
        path: 'machines',
        loadChildren: () =>
          import('./pages/monitored-machines/monitored-machines.module').then((m) => m.MonitoredMachinesModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.MONITORED_MACHINES] },
      },
      {
        path: 'setups',
        loadChildren: () => import('./pages/setups/setups.module').then((m) => m.SetupsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.SETUPS] },
      },
      {
        path: 'cluster-nodes',
        loadChildren: () => import('./pages/edge-core/edge-core.module').then((m) => m.EdgeCoreModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.EDGE_CORES] },
      },
      {
        path: 'app-processors',
        loadChildren: () => import('./pages/app-processors/app-processors.module').then((m) => m.AppProcessorsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.APP_PROCESSOR] },
      },
      {
        path: 'app-jobs',
        loadChildren: () => import('./pages/app-jobs/app-jobs.module').then((m) => m.AppJobsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.APP_JOBS] },
      },
      {
        path: 'devices',
        loadChildren: () => import('./pages/devices/devices.module').then((m) => m.DevicesModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.DEVICES] },
      },
      {
        path: 'infra-nodes',
        loadChildren: () => import('./pages/infra-node/infra-node.module').then((m) => m.InfraNodeModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.INFRA_OR_EXTERNAL_NODES] },
      },
      {
        path: 'client-apps',
        loadChildren: () => import('./pages/client-apps/client-apps.module').then((m) => m.ClientAppsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.CLIENT_APPS] },
      },
      {
        path: 'client-apis',
        loadChildren: () => import('./pages/client-apis/client-apis.module').then((m) => m.ClientApisModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.CLIENT_APIS] },
      },
      {
        path: 'device-apps',
        loadChildren: () => import('./pages/device-apps/device-apps.module').then((m) => m.DeviceAppsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.DEVICE_APPS] },
      },
      {
        path: 'events-predictions',
        loadChildren: () =>
          import('./pages/events-predictions/events-predictions.module').then((m) => m.EventsPredictionsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.EVENTS_PREDICTIONS] },
      },
      {
        path: 'operational-data',
        loadChildren: () =>
          import('./pages/operational-data/operational-data.module').then((m) => m.OperationalDataModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.EVENTS_PREDICTIONS] },
      },
      {
        path: 'file-browser',
        loadChildren: () => import('./pages/file-browser/file-browser.module').then((m) => m.FileBrowserModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.FILES] },
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [RolesGuard],
        data: { roles: [PORTAL_ROLES.REPORTS] },
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'activities',
        loadChildren: () =>
          import('./pages/system-operations/system-operations.module').then((m) => m.SystemOperationsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
