import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { RolesService } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard {
  constructor(private router: Router, private rolesService: RolesService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const routeRoles = Array.isArray(next.data.roles) ? next.data.roles : [];

    if (!routeRoles.length) {
      // route requires no role, so allow activation
      return true;
    }

    if (this.rolesService.hasAllRoles(routeRoles)) {
      return true;
    }

    this.router.navigate(['/dashboard']);
    return false;
  }
}
