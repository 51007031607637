import { Injectable } from '@angular/core';

// export function getItem(key: string): string | null {
//   return localStorage.getItem(key);
// }

// export function setItem(key: string, value: any): void {
//   localStorage.setItem(key, value);
// }

// export function removeItem(key: string): void {
//   localStorage.removeItem(key);
// }

// export function clear(): void {
//   localStorage.clear();
// }

interface Storage {
  save(key: string, value: string): void;
  get(key: string): string | undefined;
  remove(key: string): void;
  destroy(): void;
}

@Injectable({ providedIn: 'root' })
export class LocalStorage implements Storage {
  save(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  destroy(): void {
    localStorage.clear();
  }
}
