<mat-sidenav-container class="sidenav-container mat-app-background">
  <mat-sidenav
    #drawer
    class="sidenav sidenav-dark"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <m3-sidenav></m3-sidenav>
  </mat-sidenav>

  <mat-sidenav
    #infoSidebar
    class="info-sidebar"
    [style.width]="selectedInfoPanel?.width"
    position="end"
    role="dialog"
    mode="over"
    (closed)="onInfoPanelClose()"
  >
    <ng-container [ngSwitch]="selectedInfoPanel?.panelName">
      <m3-latest-operations *ngSwitchCase="'operations'" (hide)="closeInfoPanel()"></m3-latest-operations>
      <m3-docs-side-panel *ngSwitchCase="'docs'" (close)="closeInfoPanel()"> </m3-docs-side-panel>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content>
    <m3-topbar
      [isHandset]="isHandset$ | async"
      (toggleSidebar)="drawer.toggle()"
      (showLatestOperations)="showOperationsPanel()"
      (showDocs)="showDocsPanel()"
    ></m3-topbar>

    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
