import { Injectable } from '@angular/core';

import { MENU_DOCS_URLS } from './docs-urls';
import { ConfigService, IAppConfig } from '@app/state/config.service';

@Injectable()
export class DocsService {
  private currentMenuDocsUrl: string;
  private environment: IAppConfig;

  constructor(private configService: ConfigService) {
    this.environment = this.configService.readConfig();
    this.setDefaultUrl();
  }

  setCurrentDocsUrl(url: string = MENU_DOCS_URLS.DASHBOARD) {
    this.currentMenuDocsUrl = `${this.environment.DOCS_BASE_URL}${url}`;
  }

  getCurrentDocsUrl(): string {
    return this.currentMenuDocsUrl;
  }

  setDefaultUrl() {
    this.setCurrentDocsUrl(MENU_DOCS_URLS.DASHBOARD);
  }
}
