import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';

export enum SystemOperationStatus {
  STARTED = 'STARTED',
  IN_PROGRESS = 'INPROGRESS',
  FINISHED = 'FINISHED',
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'system-operation-indicator',
  template: `
    <span [matTooltip]="tooltipText" [matTooltipDisabled]="!tooltipText" [matTooltipPosition]="tooltipPosition">
      <mat-icon *ngIf="statusIcons[status]">{{ statusIcons[status] }}</mat-icon>
    </span>
  `,
  styles: [
    `
      .mat-icon {
        line-height: 20px;
        height: 20px;
        width: 20px;
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemOperationIndicatorComponent {
  readonly statusIcons = {
    [SystemOperationStatus.STARTED]: 'radio_button_unchecked',
    [SystemOperationStatus.IN_PROGRESS]: 'tonality',
    [SystemOperationStatus.FINISHED]: 'stop_circle',
  };

  @Input() status: SystemOperationStatus | string;
  @Input() tooltipText: string;
  @Input() tooltipPosition: TooltipPosition = 'below';

  constructor() {}
}
