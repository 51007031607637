import { Injectable } from '@angular/core';

import { Dictionary, DictionaryService } from './dictionary.service';
import { UserService } from './user.service';
import { RolesService } from './roles.service';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  constructor(public dictionary: DictionaryService, public user: UserService, public roles: RolesService) {}

  public updateUserInfo(jwt): void {
    this.user.updateToken(jwt);
  }

  public initializeUserState(jwt: string, roles: Array<string>, dictionary: Dictionary): void {
    this.user.updateToken(jwt);
    this.roles.updateRoles(roles);
    this.dictionary.updateDictionary(dictionary);
  }

  /*
   */
  public clearAuthState(): void {
    this.user.clearToken();
    this.roles.clearRoles();
    this.dictionary.updateDictionary();
  }
}
