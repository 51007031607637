import { Component, EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocsService } from './docs.service';

@Component({
  selector: 'm3-docs-side-panel',
  templateUrl: './docs-side-panel.component.html',
  styleUrls: ['./docs-side-panel.component.scss'],
})
export class DocsSidePanelComponent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
  currentDocsLink: SafeResourceUrl = this.getCurrentPageDocsUrl();

  constructor(private sanitizer: DomSanitizer, private docsService: DocsService) {}

  closeDocs() {
    this.close.emit();
  }

  getCurrentPageDocsUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.docsService.getCurrentDocsUrl());
  }
}
