import { Dictionary } from '@app/state';

export interface UserCredentials {
  email: string;
  password: string;
  tenantUrl: string;
}

export class LoginResponse {
  jwtToken: string;
  refreshToken: string;
  roles: Array<string>;
  dictionary: Dictionary;

  constructor(loginResponse: {
    jwtToken: string;
    refreshToken: string;
    roles: Array<{ id: string }>;
    dictionary: Dictionary;
  }) {
    this.jwtToken = loginResponse.jwtToken;
    this.refreshToken = loginResponse.refreshToken;
    const tempRoles = loginResponse.roles.map((roleObj) => roleObj.id);
    this.roles = [...tempRoles];
    this.dictionary = loginResponse.dictionary;
  }
}

export interface MultiFactorLoginResponse {
  challenge: string;
  tenantUrl: string;
  otpChallenge: boolean;
}
export interface MfaRequestPayload {
  challenge: string;
  tenantUrl: string;
  email: string;
  otp: string;
}
