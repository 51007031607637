import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AppStateService } from '../../state';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard {
  constructor(private router: Router, private appState: AppStateService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.appState.user.isAuthenticated()) {
      this.appState.clearAuthState();
      return true;
    }

    // User is authenticated i.e. not a guest so redirect to dashboard (using UrlTree)
    return this.router.parseUrl('/dashboard');
  }
}
