<div mat-dialog-title>
  Activity details
</div>

<mat-dialog-content>
  <!-- operation details summary -->
  <div class="grid grid-cols-1 sm:grid-cols-2">
    <div>
      <div class="detail-item">
        <label>Account </label>
        <span>{{ operation?.accountName || '-' }}</span>
      </div>
      <div class="detail-item">
        <label>User</label>
        <span>{{ operation?.userName || '-' }}</span>
      </div>
      <div class="detail-item">
        <label>Operation Id</label>
        <span [matTooltip]="operation?.operationId">{{ operation?.operationId || '-' | slice: 0:15 }}</span>
      </div>
    </div>

    <div>
      <div class="detail-item">
        <label>Started on</label>
        <span *ngIf="details">{{ (details[0]?.requestTime | orionDate: 'MMM d, y, HH:mm:ss.SSS') || '-' }}</span>
      </div>

      <div class="detail-item">
        <label>Ended on</label>
        <span *ngIf="details">{{
          (details[details.length - 1]?.requestOutTime | orionDate: 'MMM d, y, HH:mm:ss.SSS') || '-'
        }}</span>
      </div>

      <div class="detail-item">
        <label>Duration</label>
        <span>{{ (duratin | milliToHumanTime) || '-' }}</span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- sub operations -->
  <div class="relative">
    <div *ngIf="isLoading" class="absolute top-0 left-0 right-0">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>
    <table mat-table [dataSource]="details" class="w-full">
      <ng-container matColumnDef="api">
        <th mat-header-cell *matHeaderCellDef>API calls/signals</th>
        <td mat-cell *matCellDef="let row">
          <operation-api-col [apiName]="row.apiName" [userName]="row.userName"></operation-api-col>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef>State</th>
        <td mat-cell *matCellDef="let row">
          <operation-state-col [operationState]="row.operationState" [api_response_status]="row.apiResponseStatus">
          </operation-state-col>
        </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>Duration</th>
        <td mat-cell *matCellDef="let row">
          {{ row?.differenceInTime | milliToHumanTime }}
        </td>
      </ng-container>

      <tr style="height: 38px;" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="alert alert-danger" *ngIf="error">{{ error }}</div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="pb-2 pt-2">
    <button mat-button [mat-dialog-close]="null">Close</button>
  </div>
</mat-dialog-actions>
