import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, startWith } from 'rxjs/operators';

import { UserService, AppStateService } from '@app/state';
import { ALL_ACCOUNT_ID } from '@app/state/user-data.model';
import { TenantOrAccountSwitchService, TenantInfo, AccountInfo } from './switch.service';

@Component({
  templateUrl: './switch-dialog.component.html',
  styleUrls: ['./switch-dialog.component.scss'],
  providers: [TenantOrAccountSwitchService],
})
export class TenantOrAccountSwitchDialogComponent implements OnInit {
  public tenants$: Observable<TenantInfo[]>;
  public account$: Observable<AccountInfo[]>;
  public isLoading = false;
  public selectedTenant: { id: string; name: string };

  @ViewChild('filter') filter: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TenantOrAccountSwitchDialogComponent>,
    public user: UserService,
    private appState: AppStateService,
    private switchService: TenantOrAccountSwitchService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    const { tenantId, tenantName } = this.user.userInfo;
    this.selectedTenant = { id: tenantId, name: tenantName };

    this.tenants$ = this.switchService.getTenantsList().pipe(
      catchError((error) => {
        this.snackBar.open(error.error.message);
        return throwError(error);
      })
    );

    this.fetchAccounts(tenantId);
  }

  onTenantSelection(tenant: TenantInfo): void {
    const { id, name } = tenant;
    this.selectedTenant = { id, name };
    this.resetFilter();
    this.fetchAccounts(id);
  }

  private fetchAccounts(tenantId: string): void {
    this.isLoading = true;
    this.account$ = this.switchService.getAccounts(tenantId).pipe(
      catchError((error) => {
        this.snackBar.open(error.error.message);
        return throwError(error);
      }),
      finalize(() => (this.isLoading = false)),
      startWith([])
    );
  }

  onAccountSelection(account: AccountInfo): void {
    if (account.id === this.user.userInfo.accountId && this.selectedTenant.id == this.user.userInfo.tenantId) {
      // account is already selected so no need to call backend, just close the dialog
      this.closeDialog();
      return;
    }

    this.dialogRef.disableClose = true;
    this.isLoading = true;
    this.switchService
      .switchWorkspace(this.selectedTenant.id, account.id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.dialogRef.disableClose = false;
        })
      )
      .subscribe(
        (data) => {
          this.appState.initializeUserState(data.jwtToken, data.roles, data.dictionary);
          this.closeDialog();
          this.snackBar.open(`Switched to ${this.user.userInfo.accountName}`);
          this.router.navigateByUrl('/');
        },
        ({ error }) => this.snackBar.open(error.message)
      );
  }

  onAllAccountSelection() {
    this.onAccountSelection({ id: ALL_ACCOUNT_ID } as AccountInfo);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  resetFilter() {
    this.filter.nativeElement.value = '';
  }
}
