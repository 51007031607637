import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { AppStateService } from '../../state';
import { HeaderBuilder } from './header-builder';
import { catchError } from 'rxjs/operators';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private appState: AppStateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      // skip interceptor
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    const jwtToken = this.appState.user.getJwtToken();
    const isLoggedIn = this.appState.user.isAuthenticated();

    const requestHeaders: HttpHeaders = new HeaderBuilder(req.headers)
      .appendIf(isLoggedIn, 'Authorization', 'Bearer ' + jwtToken)
      .build();

    const requestCloned = req.clone({ headers: requestHeaders });

    return next.handle(requestCloned).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          const mockError = { ...error };
          mockError.error.message = navigator.onLine ? 'Services are unreachable' : 'Network seems offline';
          return throwError(mockError);
        }

        if (error.status === 401) {
          this.logoutUserAfterSessionExpired();
        }

        return throwError(error);
      })
    );
  }

  private logoutUserAfterSessionExpired(): void {
    this.appState.clearAuthState();
    this.router.navigateByUrl('/login');
  }
}
