import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

//
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';

import { SharedModule } from '@app/shared/shared.module';

import { MilliToHumanTimePipe } from './milli-to-human-time.pipe';
import { SystemOperationIndicatorComponent } from './operation-indicator.component';
import { OperationStateColComponent } from './operation-state-col.component';
import { OperationApiColComponent } from './operation-api-col.component';
import { OperationDetailsDialogComponent } from './operation-details-dialog/operation-details-dialog.component';
import { LatestOperationsComponent } from './latest-operations/latest-operations.component';

@NgModule({
  declarations: [
    MilliToHumanTimePipe,
    SystemOperationIndicatorComponent,
    OperationStateColComponent,
    OperationApiColComponent,
    OperationDetailsDialogComponent,
    LatestOperationsComponent,
  ],
  exports: [
    MilliToHumanTimePipe,
    SystemOperationIndicatorComponent,
    OperationDetailsDialogComponent,
    LatestOperationsComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,

    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatDialogModule,
    MatDividerModule,
    MatToolbarModule,
    MatListModule,
  ],
})
export class SharedSystemOperationsModule {}
