import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';

import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, tap } from 'rxjs/operators';

import { LatestOperationsService } from '@app/components/shared-system-operations';
import { DocsService } from '@app/components/docs-side-panel';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'm3-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  providers: [LatestOperationsService, DocsService],
})
export class BasicLayoutComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  @ViewChild('infoSidebar') infoSidebar: MatSidenav;
  selectedInfoPanel: { panelName: 'operations' | 'docs'; width: string };

  docsUrlUpdateSub: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private docsService: DocsService
  ) {}

  ngOnInit() {
    this.handleDocsUrlUpdates();
  }

  private handleDocsUrlUpdates() {
    this.docsUrlUpdateSub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router), // for initial firing,
        map((_) => {
          let childRoute = this.activatedRoute.firstChild;
          let url = childRoute.snapshot.data['docsUrl'];
          while (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
            if (childRoute.snapshot.data['docsUrl']) {
              url = childRoute.snapshot.data['docsUrl'];
            }
          }
          return url;
        }),
        distinctUntilChanged()
      )
      .subscribe((url) => {
        this.docsService.setCurrentDocsUrl(url);
      });
  }

  ngOnDestroy() {
    this.docsUrlUpdateSub?.unsubscribe();
  }

  showOperationsPanel() {
    this.selectedInfoPanel = { panelName: 'operations', width: '300px' };
    this.infoSidebar.open();
  }

  showDocsPanel() {
    this.selectedInfoPanel = { panelName: 'docs', width: '700px' };
    this.infoSidebar.open();
  }

  closeInfoPanel() {
    this.infoSidebar.close();
  }

  onInfoPanelClose() {
    this.selectedInfoPanel = null;
  }
}
