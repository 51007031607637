import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BreadCrumbItem } from '../../components/bread-crumb';

@Injectable({ providedIn: 'root' })
export class BreadCrumbService {
  private itemsSubject: BehaviorSubject<BreadCrumbItem[]> = new BehaviorSubject<BreadCrumbItem[]>([]);
  private activeItemTitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public readonly items$ = this.itemsSubject.asObservable();
  public readonly activeItemTitle$ = this.activeItemTitleSubject.asObservable();

  constructor() {}

  update(items: BreadCrumbItem[], activeItemTitle: string = ''): void {
    this.itemsSubject.next(items);
    this.activeItemTitleSubject.next(activeItemTitle);
  }

  updateActiveItemTitle(activeItemTitle: string): void {
    this.activeItemTitleSubject.next(activeItemTitle);
  }

  reset(): void {
    this.itemsSubject.next([]);
    this.activeItemTitleSubject.next('');
  }
}

@Injectable()
export class NoopBreadCrumbService {
  update(items: BreadCrumbItem[], activeItemTitle: string = ''): void {}
  updateActiveItemTitle(activeItemTitle: string): void {}
  reset(): void {}
}
