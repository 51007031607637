import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { LatestOperationsService } from '@app/components/shared-system-operations';
import { UserService } from '@app/state';

@Component({
  selector: 'm3-operations-icon-button',
  template: `
    <button mat-icon-button matTooltip="Activities" (click)="onClick()">
      <mat-icon>
        {{ (hasUnreadOperations$ | async) ? 'radio_button_checked' : 'radio_button_unchecked' }}
      </mat-icon>
    </button>
  `,
  styles: [],
})
export class OperationsIconButtonComponent implements OnInit, OnDestroy {
  @Output() showOperations = new EventEmitter();

  hasUnreadOperations$ = this.latestOperationsService.hasUnreadOperations$;
  accountSwitchEventSub: Subscription;

  constructor(private latestOperationsService: LatestOperationsService, private userService: UserService) {}

  ngOnInit() {
    this.latestOperationsService.startPolling();
    this.accountSwitchEventSub = this.userService.userInfo$.pipe(skip(1)).subscribe(() => {
      this.latestOperationsService.clearPreviousOperationsAndRestartPooling();
    });
  }

  ngOnDestroy() {
    this.latestOperationsService.stopPolling();
    this.accountSwitchEventSub?.unsubscribe();
  }

  onClick() {
    this.showOperations.emit();
    this.latestOperationsService.markLatestOperationsAsRead();
  }
}
