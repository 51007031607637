import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService } from '@app/state';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'm3-logout-confirmation-modal',
  templateUrl: './logout-confirmation-modal.component.html',
  styleUrls: ['./logout-confirmation-modal.component.scss'],
})
export class LogoutConfirmationModalComponent {
  constructor(private router: Router, private appState: AppStateService, public dialog: MatDialog) {}

  public logout(): void {
    this.appState.clearAuthState();
    this.dialog.closeAll();
    this.router.navigateByUrl('/login');
  }
}
