import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService, IAppConfig } from '@app/state/config.service';

interface HeaderInterface {
  headers: HttpHeaders;
}

@Injectable({
  providedIn: 'root',
})
export class HttpClientWrapper {
  private env: IAppConfig;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.env = this.configService.readConfig();
  }

  post<T>(apiEndPoint: string, body: any = null, httpHeaders: HttpHeaders = null): Observable<T> {
    return this.http.post<T>(this.fullUrl(apiEndPoint), body, this.constructHeaderIfMissing(httpHeaders));
  }

  get<T>(apiEndPoint: string, httpHeaders: HttpHeaders = null, httpParams: HttpParams = null): Observable<T> {
    return this.http.get<T>(this.fullUrl(apiEndPoint), {
      ...this.constructHeaderIfMissing(httpHeaders),
      params: httpParams,
    });
  }

  put<T>(apiEndPoint: string, body: any = null, httpHeaders: HttpHeaders = null): Observable<T> {
    return this.http.put<T>(this.fullUrl(apiEndPoint), body, this.constructHeaderIfMissing(httpHeaders));
  }

  // delete<T>(apiEndPoint: string, body: any = null, httpHeaders: HttpHeaders = null): Observable<T> {
  //   return this.http.delete<T>(this.fullUrl(apiEndPoint), this.constructHeaderIfMissing(httpHeaders));
  // }

  delete<T>(apiEndPoint: string, body: any = null, httpHeaders: HttpHeaders = null): Observable<T> {
    return this.http.request<T>('DELETE', this.fullUrl(apiEndPoint), {
      body,
      ...this.constructHeaderIfMissing(httpHeaders),
      responseType: 'json',
      observe: 'body',
    });
  }

  getBlob(apiEndPoint: string, httpHeaders: HttpHeaders = null): Observable<Blob> {
    return this.http.get(this.fullUrl(apiEndPoint), {
      responseType: 'blob',
      ...this.constructHeaderIfMissing(httpHeaders),
    });
  }

  requestWithProgress(
    method: string,
    apiEndPoint: string,
    body: any,
    headers: HttpHeaders
  ): Observable<HttpEvent<any>> {
    const req = new HttpRequest(method, this.fullUrl(apiEndPoint), body, { headers, reportProgress: true });
    return this.http.request(req);
  }

  private fullUrl(apiEndpoint: string): string {
    return this.env.API_BASE_URL + apiEndpoint;
  }

  private constructHeaderIfMissing(headers: HttpHeaders): HeaderInterface {
    if (headers !== null) {
      return { headers: headers };
    }
    return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  }

  // HttpHeaders

  headersOperationStarted(): HttpHeaders {
    return new HttpHeaders({ 'X-OPERATION-STATE': 'STARTED' });
  }

  headersOperationStartedWithJsonContent(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'X-OPERATION-STATE': 'STARTED',
    });
  }

  headersOperationStartedWithFormContent(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-OPERATION-STATE': 'STARTED',
    });
  }

  headersMultiPartRegister(mimeType, fileSize): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'File-Size': `${fileSize}`,
    });
  }

  headersSinglePartUpload(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/octet-stream',
    });
  }

  headersMultiPartUpload(mimeType): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'MIME-Type': mimeType,
    });
  }

  headersForCertificates(name: string, description: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      name,
      description,
    });
  }
}
