import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LocalStorage } from './utils/storage.util';

const ROLES_STORAGE_KEY = 'user-roles';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private rolesSubject = new BehaviorSubject<Array<any>>([]);
  readonly roles$ = this.rolesSubject.asObservable();

  constructor(private storage: LocalStorage) {
    const rolesArrayStr = this.storage.get(ROLES_STORAGE_KEY);
    let roles = [];

    try {
      const rolesArray = JSON.parse(rolesArrayStr);
      roles = Array.isArray(rolesArray) ? rolesArray : [];
    } catch (_) {}

    this.updateRoles(roles);
  }

  public updateRoles(roles: Array<string>): void {
    this.rolesSubject.next(roles);
    this.storage.save(ROLES_STORAGE_KEY, JSON.stringify(roles));
  }

  public clearRoles(): void {
    this.rolesSubject.next([]);
    this.storage.remove(ROLES_STORAGE_KEY);
  }

  public get roles(): Array<string> {
    return this.rolesSubject.value;
  }

  public hasAnyRole(requiredRoles: Array<string>): boolean {
    return requiredRoles.some((role) => this.roles.includes(role));
  }

  public hasAllRoles(requiredRoles: Array<string>) {
    return requiredRoles.every((role) => this.roles.includes(role));
  }
}
