<h2 mat-dialog-title>Confirm logout</h2>
<mat-dialog-content>
  Your current session will end from portal and fresh login will required to re-enter.
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="pb-2 pt-2">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="logout()">Log out</button>
  </div>
</mat-dialog-actions>
