import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';

import { BreadCrumbModule } from '../../components/bread-crumb';
import { SharedSystemOperationsModule } from '@app/components/shared-system-operations';
import { OrionDatePipeModule } from '@app/pipes/date-pipe';
import { DocsSidePanelModule } from '@app/components/docs-side-panel';

import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TopbarComponent } from './topbar/topbar.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { LogoutConfirmationModalComponent } from './logout-confirmation-modal/logout-confirmation-modal.component';
import { TenantOrAccountSwitchDialogComponent, FilterAccountsPipe } from './account-tenant-switch-dialog';
import { NotificationsCountIconButtonComponent } from './notifications-count-icon-button/notifications-count-icon-button.component';
import { OperationsIconButtonComponent } from './operations-icon-button/operations-icon-button.component';

@NgModule({
  declarations: [
    SidenavComponent,
    BasicLayoutComponent,
    TopbarComponent,
    ProfileMenuComponent,
    LogoutConfirmationModalComponent,
    TenantOrAccountSwitchDialogComponent,
    FilterAccountsPipe,
    NotificationsCountIconButtonComponent,
    OperationsIconButtonComponent,
  ],
  exports: [BasicLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    BreadCrumbModule,
    SharedSystemOperationsModule, // for latests operations
    OrionDatePipeModule,
    DocsSidePanelModule,

    // material imports
    LayoutModule,
    MatExpansionModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDividerModule,
  ],
})
export class ComponentsModule {}
