<mat-toolbar class="docs-side-panel-header">
  <div class="flex justify-between items-center w-full">
    <div>Documentation</div>
    <span class="flex-1"></span>
    <a [href]="currentDocsLink" target="_blank" mat-icon-button matTooltip="Open docs in new tab">
      <mat-icon>open_in_new</mat-icon>
    </a>
    <button mat-icon-button (click)="closeDocs()" matTooltip="Close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div class="docs-iframe-container">
  <iframe [src]="currentDocsLink" class="w-full h-full inset-0 border-0"></iframe>
</div>
