import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { LocalStorage } from './utils/storage.util';
import { UserInfo } from './user-data.model';

const TOKEN_KEY = 'jwt';

@Injectable({ providedIn: 'root' })
export class UserService {
  private jwtToken: string;
  private userInfoSubject = new BehaviorSubject<UserInfo>(new UserInfo());
  readonly userInfo$ = this.userInfoSubject.asObservable();

  constructor(private jwtHelper: JwtHelperService, private storage: LocalStorage) {
    this.jwtToken = this.storage.get(TOKEN_KEY);
    const payload = this.jwtHelper.decodeToken(this.jwtToken);
    this.updateUserInfo(payload);
  }

  private updateUserInfo(decodedJwt: any): void {
    const payload = decodedJwt || {};
    const newUserInfo = new UserInfo(payload);
    this.userInfoSubject.next(newUserInfo);
  }

  get userInfo(): UserInfo {
    return this.userInfoSubject.value;
  }

  public getJwtToken(): string {
    return this.jwtToken;
  }

  public updateToken(newToken: string): void {
    this.storage.save(TOKEN_KEY, newToken);
    this.jwtToken = newToken;
    const payload = this.jwtHelper.decodeToken(this.jwtToken);
    this.updateUserInfo(payload);
  }

  public clearToken(): void {
    this.storage.remove(TOKEN_KEY);
    this.jwtToken = null;
    this.updateUserInfo(null);
  }

  public isAuthenticated(): boolean {
    return this.jwtToken && !this.jwtHelper.isTokenExpired(this.jwtToken);
  }
}
