import { HttpClient } from '@angular/common/http';
import { Injectable, enableProdMode } from '@angular/core';

export interface IAppConfig {
  production: boolean;
  API_BASE_URL: string;
  SETUP_EDITOR_BASE_URL: string;
  DOCS_BASE_URL: string;
  PORTAL_VERSION?: string;
  API_VERSION?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration: IAppConfig;

  constructor(private httpClient: HttpClient) {}

  setConfig(): Promise<IAppConfig> {
    const url: string = '/app-configurations/app-config.json';
    return this.httpClient
      .get<IAppConfig>(url)
      .toPromise()
      .then((config) => {
        if (config.production) {
          enableProdMode();
        }
        return (this.configuration = config);
      });
  }

  readConfig(): IAppConfig {
    return this.configuration;
  }
}
