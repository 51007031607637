import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DocsSidePanelComponent } from './docs-side-panel.component';

@NgModule({
  declarations: [DocsSidePanelComponent],
  exports: [DocsSidePanelComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatToolbarModule, MatTooltipModule],
})
export class DocsSidePanelModule {}
