import { HttpHeaders } from '@angular/common/http';

export class HeaderBuilder {
  private header: HttpHeaders;

  constructor(header: HttpHeaders) {
    this.header = this.header = header;
  }

  set(name: string, value: string | string[]) {
    this.header = this.header.set(name, value);
    return this;
  }

  append(name: string, value: string | string[]) {
    this.header = this.header.append(name, value);
    return this;
  }

  setIf(condition: boolean, name: string, value: string | string[]) {
    if (!condition) {
      return this;
    }
    this.set(name, value);
    return this;
  }

  appendIf(condition: boolean, name: string, value: string | string[]) {
    if (!condition) {
      return this;
    }
    this.append(name, value);
    return this;
  }

  build() {
    return this.header;
  }
}
