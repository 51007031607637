import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

/**
 * @param value The date expression: a `Date` object,  a number
 * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
 * @param format The date/time components to include, using predefined options or a
 * custom format string.
 * @param timezone A timezone offset (such as `'+0430'`), or a standard
 * UTC/GMT or continental US timezone abbreviation.
 * When not supplied, uses the end-user's local system timezone.
 * @param locale A locale code for the locale format rules to use.
 * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
 * See [Setting your app locale](guide/i18n#setting-up-the-locale-of-your-app).
 * @returns A date string in the desired format.
 */
@Pipe({ name: 'orionDate' })
export class OrionDatePipe implements PipeTransform {
  defaultFormat = 'medium';
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null {
    if (value == null || value === '' || value !== value) return null;

    try {
      return formatDate(value, format || this.defaultFormat, locale || this.locale, timezone);
    } catch (error) {
      throw Error(`InvalidPipeArgument: '${error.message}' for pipe 'OrionDatePipe'`);
    }
  }
}
