<mat-card class="mat-elevation-z0 profile-menu">
  <!-- <mat-card-header>

  </mat-card-header> -->
  <mat-card-content>
    <div class="flex">
      <div class="avatar">
        {{ user.nameFirstLetter }}
      </div>
      <div>
        <!-- TODO: long name or email could cause text-overflow error -->
        <div>{{ user.fullName }}</div>
        <div class="mat-caption">{{ user.email }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-divider></mat-divider>

<a mat-menu-item routerLink="/settings">
  <mat-icon>settings</mat-icon>
  <span>Settings</span>
</a>

<button mat-menu-item (click)="confirmLogout()">
  <mat-icon>exit_to_app</mat-icon>
  <span>Log out</span>
</button>
